// 个人中心
import axios  from 'utils/axios'
import Qs from 'qs'

// 会员关联药店   http://172.16.0.76/  /blade-user/member/detail
export const MembertDetail=(memberId)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/member/detail';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:{id:memberId},
          
       })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}



// 获取当前登录门店详情   /blade-user/memberstore/detail

//会员信息 预存款 余额接口       已通
export const MemberInfo=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/checkedDetail';
        axios.get(url)
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 查询余额明细列表   /blade-user/memberbalancelog/page
export const BalancesDetails=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberbalancelog/page';
        axios.get(url,{params:params})
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 查询药豆明细列表   /blade-user/userpoints/pointsLogList
export const pointsLogList=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/userpoints/pointsLogList';
        axios.get(url,{params:params})
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 查询会员成长值明细列表  /blade-user/usergrowthvalue/list
export const GrowValList=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api//blade-user/usergrowthvalue/list';
        axios.get(url,{params:params})
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 预存款充值生成支付订单  /blade-user/memberrechargeorder/save    
// buyChannel	购买渠道（1：web，2：app）      money	充值金额       paymentMethod	支付方式（1：微信，2：支付宝）
export const savePayId=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberrechargeorder/save';
        axios({
            url:url,
            method:"post",
            data:Qs.parse(data)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 预存款充值接口 传id   /blade-user/memberrechargeorder/pay    post
export const PayIdSave=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberrechargeorder/pay';
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(data)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 预存款支付订单详情
// /blade-user/memberrechargeorder/detail     get
export const RechargeDetail=(params)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberrechargeorder/detail';
        axios.get(url,{params:params})
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}



// 获取当前登录账号会员列表    /blade-user/memberstore/getMemberStore
export const GetMemberStore=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/getMemberStore';
        axios.get(url,{params:params})
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//PC-根据门店名称模糊查询会员门店 get请求 /blade-user/memberstore/likeByName
export const GetLikeByName=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/likeByName';
        axios.get(url,{params:params})
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//APP关联门店 memberStoreId /blade-user/memberstore/relevanceStore
export const relevanceStore=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/relevanceStore';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 连锁设置详情  blade-user/memberstore/associationMemberStoreDetail
export const associationMemberStoreDetail=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/associationMemberStoreDetail';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//APP关联门店发送验证码  /blade-user/memberstore/relevanceStoreSms
export const relevanceStoreSms=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/relevanceStoreSms';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 删除  会员接口   GetMemberStore   /blade-user/memberstore/delete   0待提交、1.提交审核、2.初审驳回、3.初审通过，复审中、4.复审通过、5.复审不通过、6.资质过期
// 目前0才能删除
export const delMember=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstore/delete';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 最新物流   // /blade-order/delivery/newest
export const Getnewest=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/delivery/newest';
        axios.get(url)
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//设置支付密码  /blade-user/memberstore/setPassword  post
export const setPassword=(password)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstore/setPassword';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(password)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
//修改支付密码 /blade-user/memberstore/updatePassword
export const updatePayPassword=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstore/updatePassword';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 发起转账   /blade-user/memberstorebalancetransfer/save    post
export const TransferAccounts=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstorebalancetransfer/save ';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:parmes
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 树状结构 经营范围
export const BusinessScope=(memberId)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/member/blade-user/memberbusiness/listAll';
        axios.get(url,{params:memberId})
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 会员资质信息接口   get    memberStoreId
///blade-user/memberstorecertification/certificationList    
export const CreditList=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstorecertification/certificationList ';
        axios.get(url)
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 获取用户资质过期倒计时   /blade-user/memberstorecertificationconfig/
// 	类型-
// 1药品经营许可证
// 2药品经营质量管理规范认证证书
// 3食品流通许可证
// 4医疗器械经营许可证
// 5医疗机构执业许可证
// 6组织机构代码证（三证合一）
// 7税务登记证（三证合一）
// 8营业执照
// 9采购委托书
// 10采购员身份证
// 11收货员身份证
// 12年报
export const getMemberStoreCertification=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/memberstorecertificationconfig/getMemberStoreCertification ';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}




// 会员更新资质、证件  post  
// id,updateImage,updateImageBack
export const UpdateCredit=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstorecertification/update';
        axios({
            url:url,
            method:"post",
            data:Qs.parse(data)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 根据验证码修改密码--app        post请求
export const UpdatePassword=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/update-password-by-code';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            // data:Qs.stringify(data),
            data:JSON.stringify(data)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 个人中心获取优惠券数量  /blade-coupon/coupon/baseInfo/getCurrentMemberStoreCouponCount
export const CouponNum=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-coupon/coupon/baseInfo/getCurrentMemberStoreCouponCount';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 查看我的优惠券列表获取  get   /blade-coupon/coupon/baseInfo/getCurrentMemberStoreCoupon
export const LookCouponList=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-coupon/coupon/baseInfo/getCurrentMemberStoreCoupon';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 查询待领取的优惠券列表   /blade-coupon/couponFront/select-wait-received-coupon-list
export const GetCouponList=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-coupon/coupon/baseInfo/getCurrentMemberStoreCanReceiveCoupon';
        axios.get(url,{params:params})
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 代付宝申请   /blade-user/memberstorecredit/save   post

export const MemberStoreCredit=(params)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstorecredit/save';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(params)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 通过验证码修改手机号
export const updatePhoneBySmsCode=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/changeAccount';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 修改支付密码发送验证码  /blade-user/memberstore/sendValidate   post   无需参数
export const sendValidateCode=()=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstore/sendValidate';
        axios({
            url:url,
            method:"post",
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 通过验证码修改支付密码   /blade-user/memberstore/updatePasswordBySmsCode   post

export const updatePasswordBySmsCode=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstore/updatePasswordBySmsCode';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//复制购物车    /copyCart
export const CopyCart=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/ordercart/copyCart';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(datas)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 获取会员药豆     /blade-user/userpoints/userPointsInfo
export const userPointsNum=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/userpoints/userPointsInfo';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//前台-充值赠品列表 /blade-promotion/promotionMemberCharge/memberGiftList
export const memberGiftList=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-promotion/promotionMemberCharge/memberGiftList';
        axios.get(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
